import React, { useEffect } from 'react'
import Sections from '../../components/Sections';
import CustomPhrases from '../../components/customPhrases/CustomPhrases';
import useApiRequest from '../../hooks/useHandleRequests';
import { useDispatch, useSelector } from 'react-redux';
import { setNoteTemplates } from '../../slices/customNotes';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { Save } from '@mui/icons-material';
import { getnoteTemplatesPostingData } from '../../utils';

const CustomNoteTemplate = () => {
  const dispatch = useDispatch();
  const noteTemplates = useSelector(state => state.noteTemplates);
  const { apiRequest: getConfigs, loading: isLoading} = useApiRequest({
    handleResponse: (data)=> {
      dispatch(setNoteTemplates(data.msg))
    },
    handleError: (err)=> { console.log(err)}
  })

  const { apiRequest: postConfigs, loading: postingConfigs} = useApiRequest({
    handleResponse: ()=>{},
    handleError: ()=>{}
  })

  useEffect(()=>{
    getConfigs('/v2/user/config');
  },[])
  return (
    <React.Fragment>
      <Stack marginBottom="15px" direction="row-reverse">
        <Button variant='contained' disabled={postingConfigs} startIcon={<Save />} onClick={()=> postConfigs('/v2/user/config','post', getnoteTemplatesPostingData(noteTemplates))}>
          Save Changes
        </Button>
      </Stack>
      <Sections isPosting={postingConfigs} />
      <CustomPhrases />
    </React.Fragment>
  )
}

export default CustomNoteTemplate