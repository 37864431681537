// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import notesReducer from './slices/notesSlice';
import customPhraseReducer from './slices/customPhraseSlice';
import customNotesReducer from './slices/customNotes';
import noteDetailsReducer from './slices/noteDetialsSlice';
const store = configureStore({
  reducer: {
    notes: notesReducer,
    customPhrases: customPhraseReducer,
    noteTemplates: customNotesReducer,
    noteDetails: noteDetailsReducer
  },
});

export default store;
