import { Delete } from '@mui/icons-material';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react'

const Phrases = ({phrases, onDelete, onSelect}) => {
  return (
    <List>
      {phrases.map((phrase, index) => (
        <React.Fragment key={index}>
          <ListItem
            sx={{ bgcolor: '#B6CCFF', cursor: 'pointer', margin: '10px 0px'}}
            onClick={()=>onSelect(phrase.id)}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={(e) => {
                e.stopPropagation();
                onDelete(phrase.id)
              }}>
                <Delete color="error" />
              </IconButton>
            }
          >
            <ListItemText primary={phrase.title} />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export default Phrases;