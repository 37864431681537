import { Add, ArrowBack, Close, ContentCopy } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardContent, Chip, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IOSSwitch } from '../../components/styled';
import TextArealayout from '../../components/TextArealayout';
import useApiRequest from '../../hooks/useHandleRequests';
import { statasesvalues } from '../../utils';
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { useDispatch, useSelector } from 'react-redux';
import { resetNoteDetials, setnoteDetails } from '../../slices/noteDetialsSlice';

const NoteDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {note, followupID, status, summary, followup} = useSelector(state => state.noteDetails.noteDetails)
  const { apiRequest: getSingleNote} = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => dispatch(setnoteDetails(data))
  })

  console.log(note, followup, status, summary, followupID);

  const { copyHandler} = useCopyToClipboard(`${summary} \n\n\n\n\n ${followup}`);

  useEffect(()=>{
    getSingleNote(`/v2/note/${id}`);

    return ()=>{
      dispatch(resetNoteDetials());
    }
  },[])
  return (
    <React.Fragment>
      <Box component="div" marginBottom="15px">
        <Button onClick={()=> {
          navigate("/all-notes")
          dispatch(resetNoteDetials());
          }} variant='text' startIcon={<ArrowBack />}>
          Back to Dashboard
        </Button>
      </Box>
      {
        !summary && !followup ?
        <Stack justifyContent="center" marginTop="30px" gap={2} alignItems="center">
          <CircularProgress />
          <Typography variant='body2'>
            {statasesvalues[status]}
          </Typography>
        </Stack>
        : 
        (
          <>
          <Stack direction="row" gap={2} alignItems="center" marginBottom="15px">
        <Typography component="span" color='#202224' fontSize="32px" fontWeight={700}>
          {note?.patient?.innitials} | {note?.patient?.sex[0]} | {note?.patient?.age} {note?.patient?.ageType}
        </Typography>
        <Chip 
        sx={{ borderRadius: '8px', padding: '10px', bgcolor: '#2E6FF3'}} 
        clickable 
        variant='filled' 
        icon={<ContentCopy />} 
        label="Copy All" 
        color='primary'
        onClick={()=>{
          copyHandler()
        }} 
        />
      </Stack>
      <Grid container component="main" spacing={2}>
        {/* <Grid size={{lg: 6, md: 6, sm: 12, xs: 12}}>
          <Card variant='elevation' elevation={3} sx={{width: '100%', backgroundColor: '#EDF2FF'}}>
            <CardContent>
              <Stack direction="row-reverse">
                <IconButton size='large' color='primary'>
                <Close />
                </IconButton>
              </Stack>
              <Typography color='#202224' fontWeight={500} fontSize="20px">
                MIPS Notes
              </Typography>
              <Grid container component="div" spacing={2} margin="15px 0px">
                <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}}>
                  <CardActionArea>
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor="#2E6FF3" color='white' borderRadius="8px">
                    #65 Appropriate Treatment for Patients with URI
                    </Typography>
                  </CardActionArea>
                </Grid>
                <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}}>
                  <CardActionArea>
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor="white" color='#202224' borderRadius="8px">
                    #65 Appropriate Treatment for Patients with URI
                    </Typography>
                  </CardActionArea>
                </Grid>
                <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}}>
                  <CardActionArea>
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor="white" color='#202224' borderRadius="8px">
                    #65 Appropriate Treatment for Patients with URI
                    </Typography>
                  </CardActionArea>
                </Grid>
                <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}}>
                  <CardActionArea>
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor="white" color='#202224' borderRadius="8px">
                    #65 Appropriate Treatment for Patients with URI
                    </Typography>
                  </CardActionArea>
                </Grid>
                <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}}>
                  <CardActionArea>
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor="white" color='#202224' borderRadius="8px">
                    #65 Appropriate Treatment for Patients with URI
                    </Typography>
                  </CardActionArea>
                </Grid>
                <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}}>
                  <CardActionArea>
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor="white" color='#202224' borderRadius="8px">
                    #65 Appropriate Treatment for Patients with URI
                    </Typography>
                  </CardActionArea>
                </Grid>
              </Grid>
              <Stack borderRadius="8px" bgcolor="#2E6FF3" alignItems="center" direction="row-reverse" padding="5px 15px" marginBottom="15px">
                <IOSSwitch />
                <Typography flexGrow={1} textAlign="center" fontWeight={400} fontSize="14px" color='white'>
                #65 Appropriate Treatment for Patients with URI
                </Typography>
              </Stack>
              <List sx={{ bgcolor: 'white', padding: '0px', borderRadius: '8px'}}>
            <ListItemButton
            dense
            sx={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
              '&.Mui-selected': {
                backgroundColor: '#2E6FF3',
                color: 'white'
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#2E6FF3',
                color: 'white'
              }
            }}
            >
              <ListItemText 
              primary="The patient has a competing comorbid condition within the last 12 months." 
              primaryTypographyProps={{
                color: '',
                fontSize: '10px',
                fontWeight: 500
              }}
              />
            </ListItemButton>
            <ListItemButton
            sx={{
              borderBottom: '0.5px solid rgba(0, 0, 0, 0.25)',
              '&.Mui-selected': {
                backgroundColor: '#2E6FF3',
                color: 'white'
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#2E6FF3',
                color: 'white'
              }
            }}
            selected
            >
              <ListItemText 
              primary="The patient has a competing comorbid condition within the last 12 months." 
              primaryTypographyProps={{
                color: '',
                fontSize: '10px',
                fontWeight: 500
              }}
              />
            </ListItemButton>
            <ListItemButton
            sx={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
              '&.Mui-selected': {
                backgroundColor: '#2E6FF3',
                color: 'white'
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#2E6FF3',
                color: 'white'
              }
            }}
            >
              <ListItemText 
              primary="The patient has a competing comorbid condition within the last 12 months." 
              primaryTypographyProps={{
                color: '',
                fontSize: '10px',
                fontWeight: 500
              }}
              />
            </ListItemButton>
          </List>
          <Box padding="15px" bgcolor="white" margin="10px 0px">
            <Typography color='#202224' fontSize="14px" fontWeight={500} maxWidth="80%">
              {`The patient has a competing comorbid condition within the last 12 months. The comorbid condition was Other. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]`}
            </Typography>
            <Stack direction="row-reverse">
              <Button sx={{ bgcolor: '#2E6FF3'}} variant='contained' color='primary' size='large' startIcon={<Add />}>
                Insert
              </Button>
            </Stack>
          </Box>
            </CardContent>
          </Card>
        </Grid> */}
        {
          summary && (
            <Grid size={{lg: 6, md: 6, sm: 12, xs: 12}}>
              <TextArealayout id={note.id} title="Note" noteKey="summary" />
            </Grid>
          )
        }
        {
          followup && (
            <Grid size={{lg: 6, md: 6, sm: 12, xs: 12}}>
              <TextArealayout id={followupID} title="Disposition" noteKey="followup" />
            </Grid>
          )
        }
      </Grid>
          </>
        ) 
      }
    </React.Fragment>
  )
}

export default NoteDetails