import { Notes } from "@mui/icons-material";
import moment from "moment/moment";


export const sideBarWidth = 240;

export const sideBarList = [
  {
    text: 'Notes',
    icon: <Notes />,
    path: '/all-notes'
  },
  {
    text: 'Custom Note Template',
    icon: <Notes />,
    path: '/custom-note-template'
  }
]

export const sections = [
  {
    title: "Notes Sections",
    addnewOption: true,
    description: 'Select the sections you want have on your notes',
    options: ["HPI", "External Records Reviewed", "Allergies", "Medications", "Review of Systems", "Physical Exam", "Plan", "Differential Diagnosis"]
  },
  {
    title: "Care Update Sections",
    addnewOption: false,
    description: 'Select the sections you want have on your reassessment notes',
    options: ["Disposition", "Summary of encounter", "Assessment", "Medications", "MDM Risks"]
  },
  {
    title: "Disposition Sections",
    addnewOption: false,
    description: 'Select the sections you want have on your disposition notes',
    options: ["Disposition", "Summary of encounter", "Assessment", "Medications", "Differential Diagnosis", "MDM Risks"]
  },
];


export const updateSection = (index, option) => {
  sections[index].options.push(option);
}

export const allNotestableHeadData = [
  {
    label : 'Patient Identifier',
  },
  {
    label : 'Location',
  },
  {
    label: 'Created At'
  },
  {
    label : 'Status'
  },
  {
    label : 'Actions'
  },
  {
    label : 'Author',
  },
  {
    label : 'Author email',
  },
];

const adminAdditionalColumns = [
  {
    label : 'Author',
  },
  {
    label : 'Author email',
  },
  {
    label: 'Actions'
  }
]

export const getNotestableHeaders = (isSuperAdmin = false) => ([
  {
    label : 'Patient Identifier',
  },
  {
    label : 'Location',
  },
  {
    label: 'Created At'
  },
  {
    label : 'Status'
  },
  ...(isSuperAdmin ? adminAdditionalColumns : [{ label: 'Actions'}]),

])


export const dummyNotes = [
  {
    id: 1,
    patientIdentifier: 'AB | M | 20 Years',
    chiefComplaint: 'Dog Bite',
    location: 'Room 44',
    status: 'Completed',
    createdAt: '07/22/24 | 14:22',
  },
  {
    id: 2,
    patientIdentifier: 'AB | M | 20 Years',
    chiefComplaint: 'Dog Bite',
    location: 'Room 44',
    status: 'Completed',
    createdAt: '07/22/24 | 14:22',
  },
  {
    id: 3,
    patientIdentifier: 'AB | M | 20 Years',
    chiefComplaint: 'Dog Bite',
    location: 'Room 44',
    status: 'Completed',
    createdAt: '07/22/24 | 14:22',
  },
];

export const locations = ['Room 1', 'Room 2', 'Room 3'];


export const ageUnits = [
  {label: 'Years', value: 'YEARS'},
  {label: 'Months', value: 'MONTHS'},
  {label: 'Weeks', value: 'WEEKS'},
  {label: 'Days', value: 'DAYS'},
];

export const locationType = [
  {label: 'Room', value: 'ROOM'},
  {label: 'Hall', value: 'HALL'},
  {label: 'Chair', value: 'CHAIR'},
  {label: 'Other', value: 'OTHER'}
]

export const numberLocation = ['CHAIR', 'ROOM'];

export const statuses = ['Completed', 'Dispositioned'];

export const noteTypes = [
  'Notes',
  'Drafts',
  'Archived'
]

export const noteOptions = ['Care Update', 'EKG', 'Disposition'];

export const notevalues = {
  'Care Update': { label: 'Care Update',noteType: 'care-up', startStatus: 'CARE_UP_UPLOAD_STARTED', endStatus: 'CARE_UP_UPLOADED'},
  'EKG': { label: 'EKG', noteType: 'ekg', startStatus: 'EKG_UPLOAD_STARTED', endStatus: 'EKG_UPLOADED'},
  'Disposition': { label: 'Disposition', noteType: 'follow-up', startStatus: 'FOLLOW_UP_UPLOAD_STARTED', endStatus: 'FOLLOW_UP_UPLOADED'}
}


export const genders = [
  {label: 'Male', value: 'MALE'}, 
  {label: 'Female', value: 'FEMALE'}
];

export const getFormatedDateAndTime = (dateTime) =>{
  const date = moment(dateTime).format('MM/DD/YYYY');
  const time = moment(dateTime).format('hh:mm a');
  return `${date} | ${time}`;
}

export const getMobileFormatedDateAndTime = (dateTime) =>{
  const date = moment(dateTime).format('MM/DD/YYYY');
  const time = moment(dateTime).format('hh:mm a');
  return `${time} | ${date}`;
}



export const getFilteredNotes = (notes, currentPage, notesPerPage) => {
  const startIndex = (currentPage - 1) * notesPerPage;
  const endIndex = startIndex + notesPerPage;
  return notes.slice(startIndex, endIndex);
}

export const professionOptions = [
  "Emergency Medicine",
  "Primary Care Provider",
  "Resident",
  "Student",
  "Cardiologist",
  "Clinical Psychologist",
  "Dermatologist",
  "EMS",
  "Gastroenterologist",
  "Hospitalist",
  "Infectious Disease Specialist",
  "Intensivist",
  "Nephrologist",
  "OB-GYN",
  "Ophthalmologist",
  "Pediatrician",
  "Podiatrist",
  "Psychiatrist",
  "Radiologist",
  "Social Worker",
  "Speech Therapist",
  "Surgeon",
].map((p) => ({ label: p, value: p }));

export const professionGroups = [
  {
    label: "Medical",
    options: professionOptions,
  },
  {
    label: "Other",
    options: [{ label: "Other", value: "Other" }],
  },
];

export const getnoteTemplatesPostingData = (templates) => {
  const data = {}
  Object.entries(templates).forEach(([key, template])=> {
    data[key] = template.options
  })
  return data;
}

export const getSelectedNoteTypeParams = {
  'Notes': '?isArchived=false&hasFollowUp=false&noteType=note&paginate=false',
  'Drafts': '',
  'Completed': '?isArchived=false&hasFollowUp=true&noteType=note&paginate=false',
  'Archived': '?isArchived=true&noteType=note&paginate=false'
}

export const getFilterSelectedNoteTypeParams = {
  'All Notes': '?isArchived=false&noteType=note',
  'Completed': '?isArchived=false&hasFollowUp=true&noteType=note',
  'Pending': '?isArchived=false&hasFollowUp=false&noteType=note&excludeStatus=NOTE_CREATED'
}


export const statasesvalues = {
  NOTE_CREATED: "Processing Note...",
    NEW_UPLOAD_STARTED: "Processing Note...",
    NEW_UPLOADED: "Processing Note...",
    FOLLOW_UP_UPLOAD_STARTED: "Processing Follow-Up Note...",
    FOLLOW_UP_UPLOADED: "Processing Follow-Up Note...",

    CARE_UP_UPLOAD_STARTED: "Processing Care-Update Note...",
    CARE_UP_UPLOADED: "Processing Care-Update Note...",
    
    EKG_UPLOAD_STARTED: "Processing EKG Note...",
    EKG_UPLOADED: "Processing EKG Note...",
}

export const createNoteInitialState = {
  innitials: '',
  age: '',
  ageType: 'YEARS',
  sex: 'MALE',
  location: '',
  locationType: 'ROOM'
}


export const numericsLocations = ['ROOM', 'CHAIR'];


export const getNoteStatusInfo = (status) => {
  if(status === 'ERROR')
    return { text: status, color: 'error'}
  if(status === 'NOTE_CREATED')
    return { text: 'INITIALIZED', color: 'default'}
  return {text: 'PENDING', color: 'warning'}
}