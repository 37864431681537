// src/redux/customPhraseSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phrases: [],
  loading: false,
  error: null,
};

const customPhraseSlice = createSlice({
  name: 'customPhrases',
  initialState,
  reducers: {
    setPhrases: (state, action) => {
      state.phrases = action.payload;
    },
    addPhrase: (state, action) => {
      state.phrases.push(action.payload);
    },
    editPhrase: (state, action) => {
      const updatedPhrase = action.payload;
      const index = state.phrases.findIndex(phrase => phrase.id === updatedPhrase.id);
      if (index !== -1) {
        state.phrases[index] = updatedPhrase;
      }
    },
    deletePhrase: (state, action) => {
      state.phrases = state.phrases.filter(phrase => phrase.id !== action.payload);
    },
    setPhrases: (state, action) => {
      state.phrases = action.payload;
    }
  }
});

export const { addPhrase, editPhrase, deletePhrase, setPhrases } = customPhraseSlice.actions;

export default customPhraseSlice.reducer;
