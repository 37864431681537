import { useUser } from '@clerk/clerk-react';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const SingedInReRouting = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(()=>{
    console.log(user.publicMetadata);
    if(user.publicMetadata.disp_sec === undefined || 
       user.publicMetadata.disp_sec === null  ){
        navigate('/on-boarding');
    }
    else if (
      (window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.tokenHandler) ||
      (window.Android && window.Android.handleToken)
    ) {
      console.log("Opened in WebView");
      navigate("/auth-token");
    } else {
      navigate('/all-notes');
    }
  },[])

  return <></>
}

export default SingedInReRouting