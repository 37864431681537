import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  note_sec: {
    title: 'Notes Sections',
    canAddNew: true,
    content: 'Select the sections you want have on your notes',
    options: {}
  },
  careUpdate_sec: {
    title: 'Care Update Sections',
    canAddNew: true,
    content: 'Select the sections you want have on your reassessment notes',
    options: {}
  },
  disp_sec: {
    title: 'Disposition Sections',
    canAddNew: true,
    content: 'Select the sections you want have on your disposition notes',
    options: {}
  }
};

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    setNoteTemplates: (state, action) =>{
      Object.entries(action.payload).forEach(([key, values]) =>{
        state[key].options = values;
      })
    },
    toggleOption: (state, action) => {
      const {key, option, value} = action.payload;
      state[key].options[option] = value
    },
    addNewOption: (state, action) => {
      const {key, option} = action.payload;
      state[key].options[option] = true;
    }
  }
});

export const { toggleOption,setNoteTemplates, addNewOption } = sectionsSlice.actions;

export default sectionsSlice.reducer;
