import React, { useEffect, useRef, useState } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { useSelector } from "react-redux";
import useApiRequest from "../hooks/useHandleRequests";

const Item = ({ entity: { code, title } }) => <div>{`${code}: ${title}`}</div>;
const Loading = () => <div>Loading...</div>;

const TextEditor = ({ id, value, handleChange, placeholder }) => {
  const [rows, setRows] = useState(calculateRows(value || ''));
  const [debouncedValue, setDebouncedValue] = useState(value);
  const textareaRef = useRef(null);
  const { phrases } = useSelector((state) => state.customPhrases);
  const debounceDelay = 500;

  const convertPlaceholdersToText = (text) => {
    return text.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
  };

  const { apiRequest: updateNoteContent } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => console.log(data),
  });

  function calculateRows(text) {
    const lines = text.split("\n");
    let totalRows = 0;
    totalRows += lines.length;

    lines.forEach((line) => {
      const lineLength = line.length;
      const charactersPerRow = 80;
      totalRows += Math.floor(lineLength / charactersPerRow);
    });

    return Math.max(totalRows + 5, 15);
  }

  useEffect(() => {
    const newRows = calculateRows(convertPlaceholdersToText(value));
    setRows(newRows);
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, debounceDelay);

    return () => clearTimeout(handler);
  }, [value, debounceDelay]);

  useEffect(() => {
    if (debouncedValue) {
      updateNoteContent(`/v2/note/${id}`, "put", { body: debouncedValue });
    }
  }, [debouncedValue, id, updateNoteContent]);

  return (
    <ReactTextareaAutocomplete
      style={{
        maxWidth: "100%",
        minWidth: "100%",
        width: "100%",
        border: "none",
        backgroundColor: "transparent",
      }}
      ref={textareaRef}
      className="w-full rounded-lg border-[1px] border-solid border-gray-300 bg-white p-4"
      loadingComponent={Loading}
      rows={rows}
      placeholder={placeholder}
      value={convertPlaceholdersToText(value)}
      onChange={(e) => handleChange(e.target.value)}
      movePopupAsYouType
      minChar={0}
      maxLength={10000}
      trigger={{
        "*": {
          dataProvider: (token) =>
            phrases
              .filter((o) => o.code.toLowerCase().startsWith(token.toLowerCase()))
              .slice(0, 20),
          component: Item,
          output: (item) => item.content || "",
        },
      }}
    />
  );
};

export default TextEditor;
