import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URL}/api`
})

const responseBody = (response) => response.data;


const responseError = (error) => {
  console.log(error);
    return Promise.reject(error.response?.data);
};

// Axios response interceptor for global error handling
axiosInstance.interceptors.response.use(
    (response) => responseBody(response),
    (error) => responseError(error)
);

const requests = {
   get: (url, headers, body) => axiosInstance.get(url, headers),
   post: (url,headers, body) => axiosInstance.post(url,body, headers),
   patch: (url,headers, body) => axiosInstance.patch(url,body, headers),
   put: (url,headers, body) => axiosInstance.put(url,body, headers),
   delete: (url,headers, body) => axiosInstance.delete(url,headers)
};

export default requests;




