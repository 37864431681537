import React from 'react';
import Grid from '@mui/material/Grid2';
import { SignUp as SignUpComponent} from '@clerk/clerk-react';
import { Stack } from '@mui/material';

const SignUp = () => {
  return (
    <Grid sx={{ bgcolor: 'white'}} container component="main">
      <Grid display="flex" size={{ lg: 6, md: 6, sx: 12, xs: 12}} height="100vh" justifyContent="center" alignItems="center">
        <SignUpComponent
         signInUrl='/'
         fallbackRedirectUrl="/all-notes"
         signInFallbackRedirectUrl="/all-notes"
        />
      </Grid>
      <Grid sx={{
        // backgroundImage: `url('/images/time drh front image.png')`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
      }} size={{ lg: 6, md: 6, sx: 0, xs: 0}} height="100vh">
        <Stack direction="column" justifyContent="center" height="100vh" alignItems="center" display="flex">
          <img src="/images/drh new logo.jpeg" width="100%"  />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SignUp