// src/redux/customPhraseSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noteDetails: {
    note: null,
    summary: '',
    followup: '',
    followupID: null,
    status: ''
  },
  loading: false,
  error: null,
};

const noteDetailsSlice = createSlice({
  name: 'noteDetails',
  initialState,
  reducers: {
    setnoteDetails: (state, action) => {
      const {note, results} = action.payload;
      state.noteDetails = {
        followup: results.followup || '',
        summary: results.summary || '',
        followupID: results.followupID,
        status: results.status,
        note: note 
      }
    },
    updateNoteDetails: (state, action) => {
      state.noteDetails[action.payload.key] = action.payload.value;
    },
    resetNoteDetials: (state, action) => {
      state.noteDetails = initialState.noteDetails
    }
  }
});

export const { setnoteDetails, updateNoteDetails, resetNoteDetials } = noteDetailsSlice.actions;

export default noteDetailsSlice.reducer;
