import React from "react";
import { UserButton } from "@clerk/clerk-react";

const CustomUserButton = ({ showName = false }) => {
  return (
    <UserButton
      showName={showName}
      signInUrl="/all-notes"
    />
  );
};

export default CustomUserButton;