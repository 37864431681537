import { Box, Button, Divider, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { sideBarList } from '../utils';
import { Logout, Mic, Search, Settings } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';
import NotesDialog from './dialogs/NotesDialog';
import { setNotes } from '../slices/notesSlice';
import useApiRequest from '../hooks/useHandleRequests';
import { useDispatch, useSelector } from 'react-redux';

const SideBarContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { signOut } = useClerk();
  const [openNotes, setOpenNotes] = useState(false);
  const {apiRequest, loading} = useApiRequest({
    handleError: ()=> {},
    handleResponse: (data)=> dispatch(setNotes(data.body))
  })
  const { pagination } = useSelector((state) => state.notes);
  return (
    <Box component='div' display="flex" flexDirection="column" height="100vh">
      <Box component='div' padding="30px 0px 0px 0px" textAlign="center">
        <img src='/images/drh logo.png' height='31px' alt="Logo" />
      </Box>
      <Box component='div' padding='12px 0px' flexGrow={1}>
        <List>
          {sideBarList.map(item => {
            const itemSelected = `/${location.pathname.split('/')[1]}` === item.path;
            return (
              <ListItemButton
                onClick={()=> navigate(item.path)}
                key={item.text}
                sx={{
                  backgroundColor: itemSelected ? '#2E6FF3' : 'inherit',
                  '&.Mui-selected': {
                    backgroundColor: '#2E6FF3',
                    color: 'white'
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: '#2E6FF3',
                    color: 'white'
                  }
                }}
                selected={itemSelected}
              >
                <ListItemIcon sx={{ color: itemSelected ? 'white' : '#2E6FF3' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: itemSelected ? 'white' : '#2E6FF3' }} primary={item.text} />
              </ListItemButton>
            )
          })}
        </List>
        <Divider sx={{ margin: '30px 0px' }} />
        <Box component='div' padding="15px">
          <Button sx={{ fontWeight: 700 }} onClick={()=>setOpenNotes(true) } size='large' fullWidth startIcon={<Mic fontSize='large' />} variant='contained' color='error'>
            Create Note
          </Button>
        </Box>
        <Box component="div" padding="15px" marginTop="30px">
          <Typography component="p" fontFamily="sans-serif" fontSize='16px' fontWeight={600} color='#000000' textAlign="center">
            Clinical Decision Tools
          </Typography>
          <TextField
            size='small'
            fullWidth
            sx={{
              backgroundColor: '#F5F6FA',
            }}
            variant='outlined'
            placeholder='Search'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      <Box component="div" padding="15px 0px 30px 0px" borderTop="0.6px solid #E0E0E0">
        {
          process.env.SHOW_SETTINGS_BUTTON && <ListItemButton>
          <ListItemIcon>
            <Settings htmlColor='#202224' />
          </ListItemIcon>
          <ListItemText primary="Settings" color='#202224' />
        </ListItemButton>
        }
        <ListItemButton onClick={()=> signOut()}>
          <ListItemIcon>
            <Logout htmlColor='#202224' />
          </ListItemIcon>
          <ListItemText primary="Log Out" color='#202224' />
        </ListItemButton>
      </Box>
      <NotesDialog open={openNotes} handleClose={()=> {
        apiRequest(`/v2/note?isArchived=false&hasFollowUp=false&noteType=note&limit=${pagination.notesPerPage}&page=${pagination.currentPage}`, 'get');
        setOpenNotes(false)
        }} />
    </Box>
  );
}

export default SideBarContent;
