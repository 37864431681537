import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { ClerkProvider } from '@clerk/clerk-react';

const frontendApi = process.env.REACT_APP_CLERK_SECRET_KEY;
const publishableKey = process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY;

console.log(frontendApi, publishableKey)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClerkProvider
    afterSignOutUrl="/"
    frontendApi={frontendApi}
    publishableKey={publishableKey}
    appearance={{
      layout: {
        privacyPageUrl: "https://drh.ai/legal",
        helpPageUrl: "https://drh.ai/contact",
      },
      elements: {
        footerPages: {
          flexDirection: "row-reverse",
        },
        footer: {
          display: "flex",
          flexDirection: "column-reverse",
          gap: 6,
        },
        footerPagesLink__privacy: {
          color: "rgb(16, 63, 239)",
        },
      },
    }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ClerkProvider>
  </React.StrictMode>
);