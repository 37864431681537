import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const YesNoDialog = ({open, handleClose, yesAction }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Alert
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete ?
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined' color='error'>
            No
          </Button>
          <Button onClick={yesAction} variant='contained' color='primary'>
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default YesNoDialog