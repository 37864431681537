import { ArrowBack, Cancel, Check, Create, Download, Mic, PauseOutlined, Save, Stop } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, DialogContent, IconButton, Stack, styled, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { ageUnits, createNoteInitialState, genders, locationType, numericsLocations } from '../utils'
import useHandleFormik from '../hooks/usehandleFormik'
import { createNoteSchema } from '../utils/schemas'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import useApiRequest from '../hooks/useHandleRequests'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import VoiceRecorder from './AudioRecorder'
import { v4 as uuid } from 'uuid';

const StyledTextField = styled(TextField)(({theme})=>({
  '&': {
    backgroundColor: "transparent",
    
  },
  '& input' : {
    textAlign: 'center',
  },
  '& fieldset': {
    borderRadius: "16px",
  }
}))

const CreateNote = ({ initialState, setInitialState ,setDraftNotes,setIsCreating, resetRecording}) => {
  const audioBlobRef = useRef(null);
  const [audioBlob, setAudioBlob] = React.useState(null);
  const [audioUrl, setAudioUrl] = React.useState(null)
  const {  touched,errors,values, setValues, setFieldValue, getFieldProps, handleSubmit, isSubmitting} = useHandleFormik(initialState, createNoteSchema, handleCreation);
  const { apiRequest: updateStatus} = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: ()=>{}
  })


  useEffect(()=>{
    setValues(initialState)
  },[initialState])

  const { apiRequest: postNote, resetResponse} = useApiRequest({
    handleResponse: async (data)=>{
      const fileName = `recording_${new Date().toISOString()}.mp3`;
      const mp3File = new File([audioBlobRef.current], fileName, { type: "audio/mp3" });
      console.log(mp3File);
      const url = new URL(data.PreSigned)
      const headers = new Headers();
      const contentType = url.searchParams.get("Content-Type");
      if (contentType) {
        headers.set("Content-Type", contentType);
      }
      await updateStatus(`/v2/note/${data.Note.id}/status`, 'put', { status: "NEW_UPLOAD_STARTED" })
     try{
      const response = await fetch(url, {
        method: "PUT",
        body: mp3File,
        headers: headers,
      });
      console.log(response);
      await updateStatus(`/v2/note/${data.Note.id}/status`, 'put', { status: "NEW_UPLOADED" })
     }catch(err){
      console.log(err)
     }
    },
    handleError: ()=> {}
  })

  async function handleCreation(){
    await postNote('/v2/note','post', {...values, id: undefined, noteType: 'note'});
    setDraftNotes((drafts) => drafts.filter(draft => draft.id !== values.id))
    setInitialState(createNoteInitialState);
    setIsCreating(false);
  }

  const saveRecording = (blob) => {
    setAudioBlob(blob);
    audioBlobRef.current = blob
    if(blob !== null){
      const audioURL = URL.createObjectURL(blob);
      setAudioUrl(audioURL);
    }
    else {
      setAudioUrl(blob)
    }
  };
  return (
    <>
      <Box padding="10px" component="div" display="flex" justifyContent="space-between" alignItems="center">
        <IconButton onClick={()=>{
          setInitialState(createNoteInitialState)
          setIsCreating(false)
        }} size="large" color='primary'>
          <ArrowBack />
        </IconButton>
        <Typography color='#2E6FF3' fontWeight={700} fontSize="32px">
          Create Note
        </Typography>
        <Typography />
      </Box>
      <DialogContent sx={{
      height: '600px'
    }}>
        <Box component="div" padding="5px 0px">
        <Typography component="p" color='#444' fontSize="18px" fontWeight={500}>
          Sex
        </Typography>
        <Stack direction="row" gap={2}>
          {
            genders.map(gender => <Chip disabled={isSubmitting} sx={{ width: '100%', fontSize: '1rem', padding: '20px 0px'}} variant='outlined' label={gender.label} color={gender.value === values.sex ? 'primary' : 'default'} onClick={()=> setFieldValue('sex',gender.value)} />)
          }
        </Stack>
        </Box>
        <Box component="div" padding="5px 0px">
        <Typography component="p" color='#444' fontSize="18px" fontWeight={500}>
        Age
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <StyledTextField
          type='number'
          sx={{ width: '100%'}}
          margin='dense'
          placeholder='Type Here' 
          size='small' 
          {...getFieldProps('age')}
          disabled={isSubmitting}
          error={touched.age && errors.age}
          />
          {
            ageUnits.map(ageUnit => <Chip disabled={isSubmitting} sx={{ width: '100%', fontSize: '1rem', padding: '20px 0px'}} variant='outlined' label={ageUnit.label} color={ageUnit.value === values.ageType ? 'primary' : 'default'} onClick={()=> setFieldValue('ageType',ageUnit.value)} />)
          }
        </Stack>
        </Box>
        <Box component="div" padding="5px 0px">
          <Typography component="p" color='#444' fontSize="18px" fontWeight={500}>
          Patient Name Initials
          </Typography>
          <StyledTextField
            error={errors.innitials}
            margin='dense'
            placeholder='Type Here'
            fullWidth
            size='small'
            {...getFieldProps('innitials')}
            disabled={isSubmitting}
          />
        </Box>
        <Box component="div" padding="5px 0px">
        <Typography component="p" color='#444' fontSize="18px" fontWeight={500}>
        Location
        </Typography>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2} alignItems="center">
          {
            locationType.map(location => <Chip disabled={isSubmitting} sx={{ width: '48%', fontSize: '1rem', padding: '20px 0px'}} variant='outlined' label={location.label} color={location.value === values.locationType ? 'primary' : 'default'} onClick={()=> setFieldValue('locationType',location.value)} />)
          }
        </Box>
        <StyledTextField
          type={numericsLocations.includes(values.locationType) ? 'number' : 'text'}
          sx={{ width: '100%'}}
          margin='dense'
          placeholder='Type Here' 
          size='small' 
          {...getFieldProps('location')}
          disabled={isSubmitting}
          error={errors.location}
          />
        </Box>
        <Stack direction="row-reverse">
          <Button disabled={isSubmitting} onClick={()=>{
            setDraftNotes(drafts => [...drafts, {...values, noteType: 'Drafts', patient: values, createdAt: Date.now(), id: uuid()}])
            setIsCreating(false);
          }} sx={{ borderRadius: '16px'}} variant='outlined' size='medium' endIcon={<Download />} color='primary'>
          Save Draft
          </Button>
        </Stack>
        <Box component="div">
      {isSubmitting ? <Stack gap={3} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack> : <VoiceRecorder resetRecording={resetRecording} disabled={isSubmitting} saveRecording={(blob) => saveRecording(blob)} />}
      {/* <Stack justifyContent="center" margin="15px 0px" alignItems="center">
      {audioUrl && <audio controls controlsList="nodownload noplaybackrate" style={{ width: '100%'}} src={audioUrl} />}
      </Stack> */}
      {/* <Stack direction="row" alignItems=" center" justifyContent="center" gap={2} margin="15px 0px">
        <Button variant='outlined' onClick={()=> {
          setAudioBlob(null)
          setAudioUrl(null)
        }} disabled={!audioUrl}  startIcon={<Cancel />} />
        <Button onClick={handleSubmit} disabled={!audioUrl} variant='contained' startIcon={<Check />} />
      </Stack> */}
      <Stack component="div" justifyContent="center" alignItems="center">
      <Button sx={{ paddingRight: '45px', paddingLeft: '45px'}} startIcon={<Save />} variant='contained' disabled={!audioBlob || isSubmitting} onClick={handleSubmit}>
        Create
      </Button>
      </Stack>
        </Box>
      </DialogContent>
    </>
  )
}

export default CreateNote