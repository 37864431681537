import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Switch, Box, TextField, InputAdornment, IconButton } from '@mui/material';
import { Send } from '@mui/icons-material';
import { updateSection } from '../utils';
import { IOSSwitch } from './styled';

const ControlOptionsGroup = ({ addnewOption, onAddNewOption ,options,optionsStatus, onChange, controlType = 'checkbox', disabled}) => {
  const [newSection, setNewSection] = useState('');
  const ControlComponent = controlType === 'switch' ? IOSSwitch : Checkbox;
  const color = controlType === 'switch' ? 'success' : 'primary';

  return (
    <Box padding="16px" border="1px solid #2E6FF3" borderRadius="8px" margin="20px 0px" bgcolor="transparent" flexGrow={1}>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <ControlComponent
              disabled={disabled}
              color={color}
              checked={optionsStatus[option] || false}
              onChange={(e) => onChange(e.target.checked, option)}
            />
          }
          label={option}
          sx={{ display: 'block', marginBottom: '8px' }}
        />
      ))}
      {
        addnewOption && (
          <TextField
            color={color}
            variant='standard'
            placeholder='Add your own section'
            margin='dense'
            value={newSection}
            onChange={(e)=> setNewSection(e.target.value)}
            size='small'
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton disabled={newSection.trim().length === 0} onClick={()=> {
                      onAddNewOption(newSection);
                      setNewSection('');
                      }} color={color}>
                      <Send />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />
        )
      }
    </Box>
  );
};

export default ControlOptionsGroup;
