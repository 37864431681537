import { KeyboardArrowDown } from '@mui/icons-material'
import { InputAdornment, Menu, MenuItem, TextField } from '@mui/material'
import React from 'react'

const CustomSelectInput = ({options, value, onSelect}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (option) => {
    onSelect(option);
    handleClose();
  }
  return (
    <React.Fragment>
      <TextField
      sx={{
        margin: "15px 0px",
        bgcolor: "rgba(46, 111, 243, 0.1)",
        cursor: 'pointer',
      }}
      value={value}
      disabled={open}
       onClick={handleClick}
       slotProps={{
        input: {
          endAdornment: (
            <InputAdornment>
              <KeyboardArrowDown />
            </InputAdornment>
          )
        }
      }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {
          options.map(option => (
            <MenuItem sx={{ width: '200px'}} onClick={()=> handleSelect(option)}>
            {option.label || option}
            </MenuItem>
          ))
        }
      </Menu>
    </React.Fragment>
  )
}

export default CustomSelectInput