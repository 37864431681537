import { Add } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid2, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Phrases from './Phrases';
import CustomPhraseForm from './CreatePhraseForm';
import { useDispatch, useSelector } from 'react-redux';
import { addPhrase, deletePhrase, editPhrase, setPhrases } from '../../slices/customPhraseSlice';
import {v4 as uuid} from 'uuid';
import useApiRequest from '../../hooks/useHandleRequests';

const CustomPhrases = () => {
  const dispatch = useDispatch();
  const {phrases} = useSelector(state => state.customPhrases);
  const [selectedPhrase, setSelectedPhrase] = useState(null);
  const [isCreating, setisCreating] = useState(false);
  const { apiRequest: getApiRequest, loading: getLoading} = useApiRequest({
    handleResponse: (data)=> dispatch(setPhrases(data)),
    handleError: ()=> dispatch(setPhrases([]))
  });

  const {apiRequest: createApiRequest  } = useApiRequest(
    {
      handleResponse: (data) => dispatch(addPhrase(data)),
      handleError: () => {}
    }
  )

  const {apiRequest: updateApiRequest  } = useApiRequest(
    {
      handleResponse: (data) => dispatch(editPhrase(data)),
      handleError: () => {}
    }
  )
  const {apiRequest: deleteApiRequest } = useApiRequest(
    {
      handleResponse: () => {},
      handleError: () => {}
    }
  )

  useEffect(()=>{
    getApiRequest('/v2/phrase','get', {});
  },[])

  const startCreating = () =>{
   setSelectedPhrase({
    code: '',
    title: '',
    content: ''
   })
   setisCreating(true);
  }

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize="32px" fontWeight={700} fontFamily="sans-serif" color='#202224'>
        Custom Phrases
      </Typography>
      <Button onClick={startCreating} variant='contained' size='large' color='primary' startIcon={<Add />}>
        New Custom Phrase
      </Button>
      </Box>
      <Grid2 container spacing={3}>
        <Grid2 size={{ md: 5, sm: 3, xs: 12}}>
        <Box padding="16px" border="1px solid #2E6FF3" borderRadius="8px" margin="20px 0px" bgcolor="#EDF2FF" height='300px'>
          {
            phrases.length  <=0 ? (
              <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                  {
                    getLoading ? (
                      <CircularProgress />
                    ) : <Typography fontSize="16px" fontWeight={500} fontFamily="sans-serif" color='#202224'>
                    You have no custom phrases yet.
                  </Typography>
                  }
              </Box>
            ): <Phrases phrases={phrases} onSelect={(id)=>{
              setSelectedPhrase(phrases.find(item => item.id === id) || null)
              setisCreating(false);
            }} onDelete={async (id)=>{
              await deleteApiRequest(`/v2/phrase/${id}`, 'delete');
              dispatch(deletePhrase(id));
              setSelectedPhrase(null);
              setisCreating(false);
            }} />
          }
        </Box>
        </Grid2>
        <Grid2 size={{ md: 7, sm: 9, xs: 12}}>
        <Box padding="16px" border="1px solid #2E6FF3" borderRadius="8px" margin="20px 0px" bgcolor="#EDF2FF" height="300px">
          {
            selectedPhrase === null ? (
              <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                  <Typography fontSize="16px" fontWeight={500} fontFamily="sans-serif" color='#202224'>
                  You can create your own shortcuts to quickly add repetitive or frequently used information in your note. You can quickly copy them in the summary and MDM.
                </Typography>
              </Box>
            ) : (
              <CustomPhraseForm isCreating={isCreating} selectedPhrase={selectedPhrase} onSave={(values)=>{
                console.log(values);
              if(isCreating)
                createApiRequest('/v2/phrase','post',values);
              else
                updateApiRequest(`/v2/phrase/${selectedPhrase.id}`,'put',{
                  title: values.title,
                  code: values.code,
                  content: values.content
                })
                setSelectedPhrase(null);
                setisCreating(false);
              }} />
            )
          }
        </Box>
        </Grid2>
      </Grid2>
    </React.Fragment>
  )
}

export default CustomPhrases