import { Box, Card, CardContent, Chip, Divider, Pagination, Select, Skeleton, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react'
import { allNotestableHeadData, dummyNotes, getFilteredNotes, getFilterSelectedNoteTypeParams, getFormatedDateAndTime, getNotestableHeaders, getNoteStatusInfo, locations } from '../../utils';
import { removeNote, setCurrentPage, setNotes, setNoteTypeFilter } from '../../slices/notesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutline, EditNoteOutlined } from '@mui/icons-material';
import PatientInformationDialog from '../../components/dialogs/PatientInformationDialog';
import { useNavigate } from 'react-router-dom';
import useApiRequest from '../../hooks/useHandleRequests';
import YesNoDialog from '../../components/dialogs/YesNoDialog';
import { useUser } from '@clerk/clerk-react';
import CustomSelectInput from '../../components/CustomSelectInput';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(46, 111, 243, 0.1)',
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(46, 111, 243, 0.2)'
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&': {
    color: '#202224',
    fontSize: '12px',
    fontWeight: 600
  },
}));

const StyledPagination = styled(Pagination)(()=>({
  '&': {
    margin: '15px 0px'
  },
  '& ul':{
    justifyContent: 'center'
  }
}))

const AllNotes = () => {
  const {user} = useUser(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notes, filters, pagination } = useSelector((state) => state.notes);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  
  console.log(getNotestableHeaders(user.publicMetadata.isSuperAdmin));

  const {apiRequest, loading} = useApiRequest({
    handleError: ()=> {},
    handleResponse: (data)=> dispatch(setNotes(data))
  })

  const {apiRequest: deleteNote} = useApiRequest({
    handleError: (err)=> console.log(err),
    handleResponse: () => {
    }
  })

  const handleDeleteNoteSelection = (e, id) => {
    e.stopPropagation();
    setDeleteNoteId(id)
  }

  const handleDelete = (id) =>{
    deleteNote(`v2/note/${id}`, 'delete')
    dispatch(removeNote(id))
    setDeleteNoteId(null);
  }

  useEffect(()=>{
    const interval = setInterval(()=>{
      apiRequest(`/v2/note${getFilterSelectedNoteTypeParams[filters.noteType]}&paginate=true&limit=${pagination.notesPerPage}&page=${pagination.currentPage}`, 'get');
    }, 60000)
    return () => clearInterval(interval)
  },[pagination.currentPage, filters.noteType])

  useEffect(()=>{
      apiRequest(`/v2/note${getFilterSelectedNoteTypeParams[filters.noteType]}&paginate=true&limit=${pagination.notesPerPage}&page=${pagination.currentPage}`, 'get');
  },[pagination.currentPage, filters.noteType])



  const handleOpen = (e, patientData) => {
    e.stopPropagation();
    setSelectedData(patientData);
    setOpenDialog(true);
  }
  const handleClose = () => setOpenDialog(false);
  return (
    <React.Fragment>
      <Grid container spacing={2} marginBottom="30px">
        <Grid size={{ lg: 8, md: 7, sm: 12, xs: 12}}>
          <CustomSelectInput 
          options={Object.keys(getFilterSelectedNoteTypeParams)}
          value={filters.noteType}
          onSelect={(value) => dispatch(setNoteTypeFilter(value))} 
          />
        </Grid>
        <Grid size={{ lg: 4, md: 5, sm: 12, xs: 12}}>
          <Box component='div'>
          </Box>
        </Grid>
      </Grid>
      <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: '#2E6FF3'}}>
              {
                getNotestableHeaders(user.publicMetadata.isSuperAdmin).map((item) => (
                  <TableCell align='center' sx={{ color: 'white', fontWeight: 700, fontSize: '16px'}}>{item.label}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ?
              [1,2,3,4,5].map(item => <TableRow>
                {
                  [1,2,3,4,5, ...(user.publicMetadata.isSuperAdmin ? [6,7] : [] )].map(item => <TableCell key={item}><Skeleton /></TableCell>)
                }
              </TableRow>) :
               notes.map(note => {
                const status = note.hasFollowUp ? { text: 'COMPLETED', color: 'success'} :  getNoteStatusInfo(note.status)
                return <StyledTableRow  onClick={()=> navigate(`/all-notes/${note.id}`)}>
                  <StyledTableCell align='center'>{note.patient.innitials} | {note.patient.sex[0]} | {note.patient.age} {note.patient.ageType}</StyledTableCell>
                  <StyledTableCell align='center'>{note.locationType} {note.location}</StyledTableCell>
                  <StyledTableCell align='center'>{getFormatedDateAndTime(note.createdAt)}</StyledTableCell>
                  <StyledTableCell align='center'>
                    <Chip variant='outlined' color={status.color} label={status.text} />
                  </StyledTableCell>
                  {
                    user.publicMetadata.isSuperAdmin && (
                    <StyledTableCell align='center'>
                      {note.author.firstName} {note.author.lastName}
                  </StyledTableCell>
                    )
                  }
                  {
                    user.publicMetadata.isSuperAdmin && (
                    <StyledTableCell align='center'>
                      {note.author.emailAddresses[0].emailAddress}
                  </StyledTableCell>
                    )
                  }
                  <TableCell align='center'>
                    <Box borderRadius="8px" display="flex" justifyContent="space-around" alignItems="center" border="0.6px solid #D5D5D5" >
                      <Box width="100%" onClick={(e)=>handleOpen(e,{...note.patient,locationType: note.locationType, noteId: note.id})} display="flex" justifyContent="center" alignItems="center" sx={{ padding: '5px 0px', cursor: 'pointer'}}>
                      <EditNoteOutlined htmlColor='black' />
                      </Box>
                      <Divider orientation='vertical' flexItem />
                      <Box width="100%" onClick={(e)=>handleDeleteNoteSelection(e,note.id)} display="flex" justifyContent="center" alignItems="center" sx={{ padding: '5px 0px', cursor: 'pointer'}}>
                      <DeleteOutline color='error' />
                      </Box>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <CardContent>
        <StyledPagination onChange={(e, value)=> dispatch(setCurrentPage(value)) } page={pagination.currentPage} color='primary' count={pagination.pages} shape="rounded" />
      </CardContent>
      </Card>
      <PatientInformationDialog data={selectedData} open={openDialog} handleClose={handleClose} />
      <YesNoDialog handleClose={()=> {setDeleteNoteId(null)}} open={deleteNoteId} yesAction={() => handleDelete(deleteNoteId)} />
    </React.Fragment>
  )
}

export default AllNotes