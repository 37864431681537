
import React from 'react'
import SideBarContent from './SideBarContent';
import { Drawer } from '@mui/material';
import { sideBarWidth } from '../utils';

const SideBar = ({ drawerOpen, mobileOpen, setMobileOpen }) => {
  return (
    <React.Fragment>
      <Drawer
        open={drawerOpen}
        PaperProps={{
          sx: { width: `${sideBarWidth}px`, border: 'none', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }
        }}
        sx={{ backgroundColor: 'white' }} variant='persistent'>
        <SideBarContent />
      </Drawer>
      <Drawer
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        PaperProps={{
          sx: { width: `${sideBarWidth}px` , border: 'none', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }
        }}
        sx={{ backgroundColor: 'white' }} variant='temporary'>
        <SideBarContent />
      </Drawer>
    </React.Fragment>
  )
}

export default SideBar