
import React, { useEffect, useState } from 'react'
import MyNotesContent from '../MyNotesContent'
import { Dialog } from '@mui/material';
import CreateNote from '../CreateNote';
import { createNoteInitialState } from '../../utils';

const NotesDialog = ({open, handleClose}) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [draftNotes, setDraftNotes] = React.useState(JSON.parse(localStorage.getItem('notes_drafts')) || []);
  const [initialState, setInitialState] = useState(createNoteInitialState);
  const [resetRecording, setResetRecording] = React.useState(false);
  const handleResetRecording = () => setResetRecording(false); 

  useEffect(() => {
    if (!open) {
      setResetRecording(true);
    }
    handleResetRecording();
    return () => {
      setResetRecording(true);
      handleResetRecording();
    };
  }, [open]);

  useEffect(()=>{
    localStorage.setItem('notes_drafts', JSON.stringify(draftNotes))
  },[draftNotes])
  return (
    <Dialog
    sx={{
      height: '900px'
    }}
    maxWidth="md"
    fullWidth
    open={open}
    onClose={handleClose}
    >
      {
        isCreating ? 
        <CreateNote
        initialState={initialState}
        setInitialState={setInitialState}
        setIsCreating={setIsCreating}
        setDraftNotes={setDraftNotes}
        resetRecording={resetRecording}
        /> 
        : 
        <MyNotesContent
        open={open}
        setInitialState={setInitialState}
        draftNotes={draftNotes}
        setDraftNotes={setDraftNotes}
        setIsCreating={setIsCreating}
        resetRecording={resetRecording} 
        />
      }
    </Dialog>
  )
}

export default NotesDialog